import React from 'react'
import '../../CSS/Demo/dMain1.module.css'
import style from "../../CSS/Demo/dMain2.module.css";


export default function DMain2() {
    return (

        <div className={style.main}>
            <div className={style.subMain}/>
        </div>

    )

}