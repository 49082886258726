import React from 'react'
import style from '../../CSS/Demo/dHeader1.module.css'


export default function DHeader1() {
    return (

        <div className={style.header}>
            <div className={style.subHeader}>
                <div>XX</div>
                <div/>
                <div>☰</div>
            </div>
        </div>


    )

}