import React, {useContext} from 'react'
import './Footer.module.css'
import {Link} from "react-router-dom";
import {LangContext} from "./Context";

export default function Footer() {

    let txt = require('../Media/Texts/Main_' + useContext(LangContext).lang + '.json')

    return(
        <footer>
            <div>©2020-2023 Exxo Tech SARL-S <br/>
                Freelance B2B/B2C in new technologies
            </div>
            <div>Bertrange, Luxembourg - RCS B246416 <br/>
                +352 661 537 493 - contact@exxotechs.com
            </div>
            <div>
                <Link to={'/privacy'}>{txt.privacy.vp}</Link>
            </div>
        </footer>
    )
}