import React from 'react'
import style from './App.module.css'
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";


export default function Accueil(props) {


    return (

        <div className={style.ov}>
            <Header mtxt={props.lang}/>
            <Main mtxt={props.lang}/>
            <Footer/>
        </div>

    )
}