import React from 'react'
import style from '../../CSS/Demo/dTop.module.css'

export default function DTop() {
    return (

        <div className={style.top}>
            <span className={style.dot + ' ' + style.dot1}/>
            <span className={style.dot + ' ' + style.dot2}/>
            <span className={style.dot + ' ' + style.dot3}/>

        </div>


    )

}