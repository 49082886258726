import React from 'react'
import style from "../../CSS/Demo/dFooter2.module.css";


export default function DFooter2() {



    return (

        <div className={style.footer}>
            <div className={style.subFooter}>
                    See you soon !
            </div>
        </div>


    )

}