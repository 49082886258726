import React, {useContext, useEffect, useState} from 'react'
import styles from './Header.module.css'
import logo from "../Media/Images/Exxo.png";
import Menu from "./Menu";
import {MenuContext} from "./Context";
import {useNavigate} from "react-router-dom";

export default function Header(props) {
    const [rotation, setRotation] = useState(45) // Comprendre pourquoi j'ai mis 45 et pas 0 car
    const [translation, setTranslation] = useState(6) // il s'active une fois par defaut
    const [opacity, setOpacity] = useState(0)
    const {isOpen, setIsOpen} = useContext(MenuContext)


    let navigate = useNavigate();
    const routeChange = () => {
        let path = '/';
        navigate(path);
    }


    useEffect(() => {
        setRotation(isOpen === true ? 45 : 0)
        setTranslation(isOpen === true ? 6 : 0)
        setOpacity(isOpen === true ? 0 : 1)
    },[isOpen])

    return (

      <header>
            <img onClick={routeChange} src={logo} alt={"logo"}/>
            <div onClick={() => setIsOpen(!isOpen)} className={styles.navicon} >
              <span style={{transform: `rotate(${rotation}deg) translate(${translation}px, ${-translation}px)`}} ></span>
              <span style={{opacity: opacity}}></span>
              <span style={{transform: `rotate(${-rotation}deg) translate(${translation}px, ${translation}px)`}} ></span>
            </div>
          {isOpen && <Menu open={isOpen} text={props.mtxt}/>}
      </header>

    )
}