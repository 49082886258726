import React from "react";
import { useState } from "react";
import {motion, AnimatePresence, LayoutGroup} from "framer-motion";
import styles from "./MotionX.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function MotionX(props) {
  const items = [0, 1, 2];
  const logos = props.text.logos
  const  txt =  props.text.pma
  const coord = props.text.coord

  function Item(res) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    function Content() {

      return (
        <motion.div
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <p className={styles.contCord}>{res.cont}</p>
        </motion.div>
      );
    }

    return (
      <motion.li layout onClick={toggleOpen} >
        <motion.div className={styles.avatar}  layout >
            <FontAwesomeIcon icon={res.lgx}/>
            <span className={styles.txtCord}>{res.txt}</span>
        </motion.div>
        <AnimatePresence>{isOpen && <Content cord={res.cont}/>}</AnimatePresence>
      </motion.li>
    );
  }

  return (
    <LayoutGroup id={"a"}>

      <motion.ul layout >
        {items.map(item => (
          <Item key={item} lgx={logos[item]} cont={coord[item]}
          txt={txt[item]}/>
        ))}
      </motion.ul>
    </LayoutGroup>
  );
}
