import React from 'react';
import ParticleImage, {forces, ParticleForce, ParticleOptions} from "react-particle-image";
import img from "../Media/Images/Exxo.png"
import useWindowSize from "@rooks/use-window-size";

export default function PartX() {

    const particleOptions: ParticleOptions = {
        filter: ({x, y, image}) => {
            // Get pixel
            const pixel = image.get(x, y);
            // Make a particle for this pixel if blue > 50 (range 0-255)
            return pixel.b > 100;
        },
        color: () => 'RGB(220,220,220,1)',
        radius: () => Number(innerWidth) < 800 ? Math.floor(Math.random() * 2 + 1)   : Math.floor(Math.random() * 4 + 2),
        mass: () => 500,
        friction: () => 0.5
    };

    const motionForce = (x: number, y: number): ParticleForce => {
        return forces.disturbance(x, y, 5);
    };

    const {innerWidth} = useWindowSize();

    return (
        <ParticleImage
            src={img}
            scale={Math.min(Number(innerWidth) / 3400, 0.4)}
            entropy={100}
            maxParticles={Number(innerWidth) < 800 ? Number(innerWidth)   : (Number(innerWidth)  )}
            particleOptions={particleOptions}
            backgroundColor={"RGBA(0,0,0,0)"}
            mouseMoveForce={motionForce}
            touchMoveForce={motionForce}
            width={Math.min(Number(innerWidth)*0.8 , 1200)}
            height={Math.min(Number(innerWidth) * 0.4, 600)}
        />
    )

}
