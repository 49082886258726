import React from 'react'
import '../../CSS/Demo/dFooter1.module.css'
import style from "../../CSS/Demo/dFooter1.module.css";


export default function DFooter1() {



    return (

        <div className={style.footer}>
            <div className={style.subFooter}>
                ©2020-2023 Exxo
            </div>
        </div>


    )

}