import React, {useContext} from 'react'
import style from './Menu.module.css'
import {LangContext, MenuContext} from "./Context";
import {HashLink} from 'react-router-hash-link'
import {Link} from "react-router-dom";

export default function Menu(props) {

    const { lang, setLang } = useContext(LangContext)
    const { isOpen, setIsOpen } = useContext(MenuContext)

    let languages = {
        fr: require('../Media/Images/drapeaux/fr.svg').default,
        lu: require('../Media/Images/drapeaux/lu.svg').default,
        en: require('../Media/Images/drapeaux/en.svg').default,
        de: require('../Media/Images/drapeaux/de.svg').default
    }

    let txt = require('../Media/Texts/Main_' + lang + '.json')

    const buttonStyle = {
        transform: props.open ? 'translateY(-1px)' : 'translateY(0)'
    }


    return (

        <div className={style.menu}>
            <div className={style.sMenu}>

                <ul style={buttonStyle}>

                    {txt.menu.map((item, index) => (

                        item.link === 'privacy' ?
                            <Link to={'privacy'}>
                                <li>{item.text}</li>

                            </Link>
                            :
                            <HashLink smooth to={'/#' + item.link} key={index} onClick={() => setIsOpen(!isOpen)}>
                                <li>{item.text}</li>
                            </HashLink>


                    ))}


                    <div className={style.drp}>
                        <img onClick={() => setLang("fr")} src={languages.fr} alt={'fr'} />
                        <img onClick={() => setLang("lu")} src={languages.lu} alt={'lu'}/>
                        <img onClick={() => setLang("en")} src={languages.en} alt={'en'}/>
                        <img onClick={() => setLang("de")} src={languages.de} alt={'de'}/>
                    </div>

                </ul>

            </div>
        </div>

    )
}