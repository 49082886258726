import React, {useContext, useState} from 'react'
import styles from './Main.module.css'
import style from './MainDemo.module.css'
import exxoImg from '../Media/Images/Exxo shadow.png'
import sloganImg from '../Media/Images/slogan shadow.png'
import luxImg from '../Media/Images/LuxImg.jpg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChartLine} from '@fortawesome/fontawesome-free-solid'
import {faCaretLeft, faCaretRight} from "@fortawesome/fontawesome-free-solid";

import * as fontawesome from "@fortawesome/fontawesome";
import MotionX from "./MotionX";
import Trx from "./Trx";
import {BrixContext, ColorContext, LangContext} from "./Context";
import PartX from "./Particule";
import DTop from "./Demo/dTop";
import * as Comp from './Demo/DemoExport'
import Brx from "./Brx";
// import AnimationVideo from "./AnimationVideo";

fontawesome.library.add(faCheck, faChartLine, faCaretLeft, faCaretRight);

export default function Header(props) {
    const [clr, setClr] = useState('RGBA(130, 200, 200, 0.8)');

    const [hd, setHd] = useState(1)
    const [mn, setMn] = useState(1)
    const [ft, setFt] = useState(1)

    const {isActive, setIsActive} = useContext(BrixContext)

    let CompHead = Comp['DHeader' + hd]
    let CompMain = Comp['DMain' + mn]
    let CompFoot = Comp['DFooter' + ft]

    let txt = require('../Media/Texts/Main_' + useContext(LangContext).lang + '.json')

    const handleActive = (img) => {
        setIsActive(((isActive === false) || (isActive !== img)) ? img : false)
    }

    return (<main>

            <div className={styles.topMain}>
                <span className={styles.partX}>
                    <PartX/>
                </span>
                <div className={styles.imgX}>
                    <img className={styles.exxo} src={exxoImg} alt={'Exxo Techs'}/>
                    <img className={styles.slogan} src={sloganImg} alt={'Your stand out partner'}/>
                </div>
            </div>

            <div className={styles.qqMots} id={'about'}>
                <h2>{txt.qqmots.titre}</h2>

                {txt.qqmots.text.map((res, index) => {
                    return(<p key={index}>
                        {res}
                    </p>)
                })
                }

                {/*<a href={'détails'} className={styles.link}>Détails</a>*/}
            </div>

                    <div className={style.screenDemo}>
                        <div className={style.lArrow}>
                            <div onClick={() => {
                                hd === 1 ? setHd(2) : setHd(hd - 1)
                            }}>         <FontAwesomeIcon icon={faCaretLeft} className={style.iconex}/> </div>
                            <div onClick={() => {
                                mn === 1 ? setMn(3) : setMn(mn - 1)
                            }}>         <FontAwesomeIcon icon={faCaretLeft} className={style.iconex}/>  </div>
                            <div onClick={() => {
                                ft === 1 ? setFt(2) : setFt(ft - 1)
                            }}>         <FontAwesomeIcon icon={faCaretLeft} className={style.iconex}/>  </div>
                        </div>
                        <div className={style.screen}>
                            <DTop/>
                            <CompHead/>
                            <CompMain/>
                            <CompFoot/>
                        </div>
                        <div className={style.rArrow}>
                            <div onClick={() => { hd === 2 ? setHd(1) : setHd(hd + 1) }}>
                                <FontAwesomeIcon icon={faCaretRight} className={style.iconex}/>
                            </div>
                            <div onClick={() => { mn === 3 ? setMn(1) : setMn(mn + 1) }}>
                                <FontAwesomeIcon icon={faCaretRight} className={style.iconex}/>
                            </div>
                            <div onClick={() => { ft === 2 ? setFt(1) : setFt(ft + 1) }}>
                                <FontAwesomeIcon icon={faCaretRight} className={style.iconex}/>
                            </div>
                        </div>
                    </div>


            <div className={styles.technos} id={'technos'}>
                <h2>{txt.technos.titre}</h2>

                    {txt.technos.text.map((res, index) => {
                        return (<div className={styles.bb} key={index}>
                            <p>{res.text}</p>
                            <img src={require('../Media/Images/Logos/Exxo-' + res.img + '.png')} alt={'Brix'} onClick={() => handleActive(res.img)}/>
                        </div>)
                    })}
            </div>

            {isActive && <Brx act={isActive} setAct={setIsActive} vals={txt.technos.text} />}


            <div className={styles.qqMots}>
                <h2>{txt.chiffres.titre}</h2>

                <div className={styles.chiffres}>

                    {txt.chiffres.cont.map((res, index) => {
                        return (<div className={styles.subChiffres} key={index}>
                                <img src={require('../Media/Images/' + res.font)} alt={res.font}/>
                                <h4>{res.text}</h4>
                            </div>)
                    })}
                </div>
            </div>

            <ColorContext.Provider value={{clr,setClr}}>
            <div className={styles.technos} style={{backgroundColor:clr, transitionDuration:'1s'}}>
                <h2>Le public cible</h2>
                <Trx/>
            </div>
            </ColorContext.Provider>

            {/*<AnimationVideo/>*/}

            <img src={luxImg} className={styles.luximg} alt={'Exxo Au luxembourg'}/>

            <div className={`${styles.qqMots} ${styles.mapx}`}>


                <div className={styles.conTitre} id={'contact'}>
                    {/*<h2>Contact</h2>*/}
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2585.74908500006!2d6
                    .0732852!3d49.602481000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.
                    1!3m3!1m2!1s0x479549803221086d%3A0x35db0aa421e5fda7!2sExxo%20Techs!5e0!3m2!1sfr!
                    2slu!4v1670194389768!5m2!1sfr!2slu" allowFullScreen="" loading="lazy" title={"Map"}
                        referrerPolicy="no-referrer-when-downgrade"/>

                <p className={styles.coord}>{txt.contact.text}</p>

                <span>
                    <MotionX text={txt.contact}/>
                </span>

            </div>


        </main>

    )
}