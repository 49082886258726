import React, {useContext} from 'react'
import styles from './Privacy.module.css'
import Header from "./Header";
import Footer from "./Footer";
import {LangContext} from "./Context";


export default function Privacy(props) {

    let txt = require('../Media/Texts/Main_' + useContext(LangContext).lang + '.json')

    return (

        <div className={styles.ovp}>
            <Header mtxt={props.lang}/>
                <div className={styles.priv}>
                    <h2>{txt.privacy.title}</h2>
                    <p>{txt.privacy.text}</p>
                </div>
            <Footer/>
        </div>

    )
}