import React from "react"

export const ColorContext = React.createContext(null)
export const LangContext = React.createContext({
    lang: navigator.language.substring(0,2),
    setLang: () => {}
})

export const MenuContext = React.createContext({
    isOpen: false,
    setIsOpen: () => {}
})

export const BrixContext = React.createContext({
    isActive: false,
    setIsActive: () => {}
})