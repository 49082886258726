import React, {useState} from 'react';
import styles from './App.module.css';
import {LangContext, MenuContext, BrixContext} from "./Context";
import Accueil from "./Accueil";
import {Route, Routes} from "react-router-dom";
import Privacy from "./Privacy";

function App() {

    const [lang, setLang] = useState(navigator.language.substring(0, 2));
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const lg = {lang, setLang};
    const mn = {isOpen, setIsOpen};
    const ac = {isActive, setIsActive}


    return (
        <LangContext.Provider value={lg}>
            <MenuContext.Provider value={mn}>
                <BrixContext.Provider value={ac}>
                <div className={styles.app}>

                    <Routes>
                        <Route path="/" element={<Accueil/>}/>
                        <Route path="/privacy" element={<Privacy/>}/>
                    </Routes>
                </div>
                    </BrixContext.Provider>
            </MenuContext.Provider>
        </LangContext.Provider>
    );
}

export default App;
