import React from 'react'
import style from './Brx.module.css'


export default function Brx(props) {

    const img = props.vals.filter(obj => obj.img === props.act).map(obj => obj.img)[0]
    const textInt = props.vals.filter(obj => obj.img === props.act).map(obj => obj.textInt)[0]
    const color = props.vals.filter(obj => obj.img === props.act).map(obj => obj.color)[0]
    const imgInt = props.vals.filter(obj => obj.img === props.act).map(obj => obj.imgInt)[0]
    const lnk = props.vals.filter(obj => obj.img === props.act).map(obj => obj.link)[0]
    const txtLink = props.vals.filter(obj => obj.img === props.act).map(obj => obj.txtLink)[0]

    return (
        <div className={style.brxCont} style={{backgroundColor:color}}>
            <div className={style.brxSubCont}>
                <div onClick={() => props.setAct(!props.act)} className={style.naviconx} >
                  <span style={{transform: `rotate(45deg) translate(6px, 24px)`}} ></span>
                  <span style={{transform: `rotate(-45deg) translate(6px, -24px)`}} ></span>
                </div>

                        {/*// Mettre plutot le pop up en top d'écran et pas top du div actuel*/}

                <img src={require('../Media/Images/Logos/Exxo-' + img + '.png')} alt={'Brix'}/>
                <p>{textInt}</p>
                <img src={require('../Media/Images/brix/' + imgInt)} alt={imgInt}/>
                <a href={lnk}>{txtLink}</a>
            </div>
        </div>

    )
}