import React from 'react'
import '../../CSS/Demo/dMain1.module.css'
import style from "../../CSS/Demo/dMain1.module.css";


export default function DMain1() {
    return (

        <div className={style.main}>
            <div className={style.subMain}/>
            <div className={style.subMain}>
                Welcome to this wonderful Website !
            </div>
            <div className={style.subMain}>
                I hope you will like it !
            </div>
        </div>

    )

}