import React, {useContext, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import items from '../Media/Texts/Main_fr.json'
import styles from './Trx.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/fontawesome-free-solid";
import {ColorContext} from "./Context";

const Trx = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const {setClr} = useContext(ColorContext);
    const [isDragging, setIsDragging] = useState(false);

    const handlePrevClick = () => {
        if (currentIndex === 0) {
            setCurrentIndex(items.concerne.length - 1);
            setClr(items.concerne[items.concerne.length - 1].color)
        } else {
            setCurrentIndex(currentIndex - 1);
            setClr(items.concerne[currentIndex - 1].color)

        }
    };

    const handleNextClick = () => {
        if (currentIndex === items.concerne.length - 1) {
            setCurrentIndex(0);
            setClr(items.concerne[0].color)
        } else {
            setCurrentIndex(currentIndex + 1);
            setClr(items.concerne[currentIndex + 1].color)
        }
    };

    const handleDrag = (e) => {

        if(isDragging) {

            if ((e.clientX > window.innerWidth * 0.75)) {
                handleNextClick()
                setIsDragging(false)
            }

            if ((e.clientX < window.innerWidth * 0.25 )) {
                handlePrevClick()
                setIsDragging(false)
            }

        }

    }

        const handleTouch = (e) => {

        let touch = e.touches[0]

        if(isDragging) {

            if ( (touch.clientX > window.innerWidth * 0.75 )) {
                handleNextClick()
                setIsDragging(false)
            }

            if ( (touch.clientX < window.innerWidth * 0.25 )) {
                handlePrevClick()
                setIsDragging(false)
            }

        }

    }

    return (
        <div draggable className={styles.trx}
                                        onDrag={handleDrag}
                                        onTouchMove={handleTouch}
                                        onTouchStart={() => setIsDragging(true)}
                                        onTouchEnd={() => setIsDragging(false)}
                                        onDragStart={() => setIsDragging(true)}
                                        onDragEnd={() => setIsDragging(false)}
        >
            <button onClick={handlePrevClick} className={styles.trx_button}>
                <FontAwesomeIcon icon={faCaretLeft}/>
            </button>
            <div className={styles.trx_c}>
                <AnimatePresence>
                    {items.concerne.map((item, index) => {
                        const isCurrent = index === currentIndex;
                        return (
                            <motion.div className={styles.trx_ext}
                                        key={index}
                                        initial={{x: '-120%'}}
                                        animate={{x: isCurrent ? '0%' : '120%'}}
                                        exit={{x: '-120%'}}
                                        variants={{
                                            enter: {transition: {delay: '1s'}},
                                            exit: {transition: {delay: 0}, opacity: 0}
                                        }}
                            >
                                <div className={styles.trx_centre}>
                                    <img src={require('../Media/Images/Carousel/' + item.image)}
                                         alt={item.image.slice(0, -4)}/>
                                    <p>{item.text}</p>
                                </div>
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            </div >

            <button onClick={handleNextClick} className={styles.trx_button}>
                <FontAwesomeIcon icon={faCaretRight}/>
            </button>
        </div>
    );
};

export default Trx;
